import { usePublicationStore } from '@/lib/store/publication-list';
import { useSession } from 'next-auth/react';
import { Hits } from 'react-instantsearch';
import { toast } from 'sonner';
import FiltersWithSearch from './filters-with-search';
import PublicationListController from './publication-list/controller';
import ClearRefinements from './widgets/clearRefinements';
import CustomPagination from './widgets/customPagination';
import Error from './widgets/error';
import LoadingIndicator from './widgets/loadingIndicator';
import NoResults from './widgets/noResults';
import NoResultsBoundary from './widgets/noResultsBoundary';
import PublicationCardHit from './widgets/publicationCardHit';
import ShowingTotalResults from './widgets/total-results';

const PublicationList = ({
  handlePublicationInquiryOpen,
  handleRequirementsOpen,
  isWhitelabelOwner,
  listName = '',
}) => {
  // const { status: algoliaStatus } = useInstantSearch();
  const { status } = useSession();
  const { selectedSavedList, selectedPublications } = usePublicationStore(
    (state) => state
  );

  const handleCopyClipboard = () => {
    const queries = window.location.search;
    const route = `${window.location.origin}/shared-publications`;

    const search = hasListName
      ? `${route}${queries}`
      : `${route}${queries}&list_name=${selectedSavedList?.name}`;

    navigator.clipboard
      .writeText(search)
      .then(() => toast.success('Link Copied!'));
  };

  const hasListName = listName || selectedSavedList?.name;
  const hasUser = status === 'authenticated';

  // if (algoliaStatus === 'stalled') {
  //   return <LoadingOverlay title="Retrieving.." />;
  // }

  return (
    <>
      {hasUser && (
        <div className="flex justify-between items-center">
          {hasListName && <ClearRefinements action="back" />}
          {(selectedPublications?.length > 0 || hasListName) && (
            <FiltersWithSearch className="ml-auto" />
          )}
        </div>
      )}
      <div id="press-lists">
        <div className="flex flex-col md:flex-row gap-6 md:items-center justify-between my-6">
          <h2 className="text-left text-3xl sm:text-[2.5rem] sm:leading-none text-primary-900 flex-1">
            {hasListName ? hasListName : 'All Publications'}
          </h2>
          {!hasUser && (
            <FiltersWithSearch className="flex justify-between items-center flex-wrap gap-4" />
          )}
          {hasUser && (
            <PublicationListController
              handleCopyClipboard={handleCopyClipboard}
              hasListName={hasListName}
            />
          )}
        </div>
        <ShowingTotalResults />
        <Error />
        <NoResultsBoundary fallback={<NoResults />}>
          <LoadingIndicator />
          <Hits
            hitComponent={({ hit }) => (
              <PublicationCardHit
                data={hit}
                handlePublicationInquiryOpen={handlePublicationInquiryOpen}
                handleRequirementsOpen={handleRequirementsOpen}
                isWhitelabelOwner={isWhitelabelOwner}
                hasListName={hasListName}
                hasUser={hasUser}
              />
            )}
            classNames={{ list: 'space-y-2' }}
          />
        </NoResultsBoundary>
      </div>
      <CustomPagination />
    </>
  );
};

export default PublicationList;
