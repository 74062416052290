import { usePublicationStore } from '@/lib/store/publication-list';
import { XCircleIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { useClearRefinements } from 'react-instantsearch';

const ClearRefinements = ({ action = 'clear', ...props }) => {
  const { canRefine, refine } = useClearRefinements(props);

  const handleClearSelectedList = usePublicationStore(
    (state) => state.handleClearSelectedSavedList
  );

  const router = useRouter();

  const handleClearRefinement = () => {
    refine();
    handleClearSelectedList();
    router.push('/publications', undefined, { shallow: true });
  };

  return (
    <button
      disabled={!canRefine}
      onClick={handleClearRefinement}
      className="text-gray-600 font-bold inline-flex items-center"
    >
      {action === 'clear' ? (
        <>
          <XCircleIcon className="w-5 h-5 mr-2" />
          <span>Clear all</span>
        </>
      ) : (
        <ArrowLeftIcon className="w-5 h-5" />
      )}
    </button>
  );
};

export default ClearRefinements;
