import CartContext from '@/components/CartContext';
import Tooltip from '@/components/tooltip';
import { Checkbox } from '@/components/ui/checkbox';
import PublicationModelDuplicate from '@/lib/models/publication-model-duplicate';
import { usePublicationStore } from '@/lib/store/publication-list';
import {
  ExternalLinkIcon,
  PencilIcon,
  StarIcon,
} from '@heroicons/react/outline';
import { StarIcon as StarIconSolid } from '@heroicons/react/solid';
import Image from 'next/image';
import { useContext } from 'react';
import {
  DealExpirationStatus,
  DomainAuthorityRankingLabel,
  ESTTurnaroundTimeLabel,
  IsDoFollowLabel,
  NewsLabel,
  SponsorLabel2,
} from '../../labels';
import PublicationCTAButton from '../publication-cta-btn';
import PublicationCategories from '../publicationCategories';

const PublicationCardHit = ({
  data,
  isWhitelabelOwner,
  handleRequirementsOpen,
  handlePublicationInquiryOpen,
  hasListName,
  hasUser,
}) => {
  const publication = data?.deal ? data : new PublicationModelDuplicate(data);

  const { handleAddToList, handleRemoveToList, selectedPublications } =
    usePublicationStore((state) => state);

  const {
    handleAddItem,
    handleRemoveItem,
    canViewPricing,
    handleFeaturedUpdate,
  } = useContext(CartContext);

  const handlePublicationSelect = (publication) => {
    const updatedPublication = {
      ...publication,
      price: publication.deal
        ? publication?.discountedPrice
        : publication.price,
    };

    if (
      canViewPricing &&
      publication.requiresInquiry &&
      !isWhitelabelOwner &&
      !publication?.requirements
    ) {
      handlePublicationInquiryOpen(updatedPublication);
      return;
    }
    if (
      canViewPricing &&
      !isWhitelabelOwner &&
      (publication?.requirements || publication?.exampleScreenshot)
    ) {
      handleRequirementsOpen(updatedPublication);
    } else if (isWhitelabelOwner && !publication.isHidden) {
      handleRemoveItem(updatedPublication, 'isHidden');
    } else if (isWhitelabelOwner && publication.isHidden) {
      handleAddItem(updatedPublication, 'isHidden');
    } else {
      handleAddItem(updatedPublication);
    }
  };

  return (
    <div className="bg-white rounded-xl">
      <div className="w-full flex-col text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none">
        <div className="flex gap-2 md:gap-4 p-6 lg:p-8 items-start sm:items-center">
          {/* LOGO */}
          <div className="flex-shrink-0">
            {publication?.logo && (
              <Image
                className="object-contain rounded-full"
                src={
                  publication.logo?.url || publication?.logo?.attributes?.url
                }
                alt={publication.name}
                width={44}
                height={44}
              />
            )}
          </div>

          {/* PUBLICATION DETAILS */}

          <div>
            <div className="flex items-center gap-2">
              <p className="font-bold text-gray-700">{publication?.name} </p>

              <DealExpirationStatus endDate={publication?.deal?.end_date} />
            </div>
            <div className="flex flex-col sm:flex-row items-start md:items-center gap-2">
              <a
                className="text-gray-500"
                href={publication.websiteUrl}
                target="_blank"
                rel="noreferrer"
              >
                <p className="break-words w-full md:w-full max-w-[250px] md:max-w-[auto]">
                  {publication.websiteUrl}
                </p>
              </a>
              {publication?.exampleScreenshot && (
                <button
                  className="relative whitespace-nowrap inline-flex items-center justify-center text-gray-500 hover:text-gray-600 font-bold gap-1 ring-0 outline-none"
                  onClick={() => handlePublicationSelect(publication)}
                >
                  <ExternalLinkIcon
                    className="h-5 w-5 text-gray-600 inline"
                    aria-hidden="true"
                  />
                  View Example
                </button>
              )}

              {hasUser && !hasListName && (
                <div className="flex items-center gap-x-2">
                  <Checkbox
                    id={`add_to_list_pub_${publication.id}`}
                    checked={Boolean(
                      selectedPublications?.find(
                        (currentPublicationId) =>
                          currentPublicationId === publication.id
                      )
                    )}
                    onCheckedChange={(checked) => {
                      if (checked) {
                        handleAddToList(publication.id);
                      } else {
                        handleRemoveToList(publication.id);
                      }
                    }}
                  />
                  <label
                    htmlFor={`add_to_list_pub_${publication.id}`}
                    className="text-gray-600 text-sm font-semibold"
                  >
                    Add to List
                  </label>
                </div>
              )}
            </div>
          </div>

          {/* PRICE & CTA */}

          <div className={'ml-auto font-bold'}>
            {canViewPricing && (
              <>
                {isWhitelabelOwner ? (
                  <>
                    <div
                      className="flex items-center gap-1 cursor-pointer"
                      // onClick={() => handlePriceEdit(publication)}
                    >
                      <p>
                        {publication?.deal
                          ? publication?.getFormattedDiscountedPrice()
                          : publication?.getFormattedPrice()}
                      </p>{' '}
                      <PencilIcon
                        className="block h-4 w-4"
                        aria-hidden="true"
                      />
                    </div>
                    {publication?.deal && (
                      <p className="text-sm text-primary font-bold">
                        Typically {publication?.getFormattedPrice()}
                      </p>
                    )}
                  </>
                ) : (
                  <div className="text-right">
                    <p>
                      {publication?.deal
                        ? publication?.getFormattedDiscountedPrice()
                        : publication?.getFormattedPrice()}
                    </p>
                    {publication?.deal && (
                      <p className="text-sm text-primary font-bold">
                        Typically {publication?.getFormattedPrice()}
                      </p>
                    )}
                  </div>
                )}
              </>
            )}
          </div>

          <div className="hidden sm:flex justify-end gap-2">
            <div className="hidden md:block">
              <PublicationCTAButton
                canViewPricing={canViewPricing}
                isWhitelabelOwner={isWhitelabelOwner}
                isHidden={publication?.isHidden}
                isRequireInquiry={publication?.requiresInquiry}
                handlePublicationSelect={() =>
                  handlePublicationSelect(publication)
                }
              />
            </div>

            {isWhitelabelOwner && (
              <div className="flex flex-col justify-center items-center">
                {publication.isFeatured ? (
                  <>
                    <Tooltip content={'Remove from featured'}>
                      <StarIconSolid
                        className="block h-6 w-6 text-purple-600"
                        aria-hidden="true"
                        onClick={() =>
                          handleFeaturedUpdate(publication, 'isFeatured')
                        }
                      />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip content={'Set as featured'}>
                      <StarIcon
                        className="block h-6 w-6 text-purple-600"
                        aria-hidden="true"
                        onClick={() =>
                          handleFeaturedUpdate(publication, 'isFeatured')
                        }
                      />
                    </Tooltip>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="border-t border-primary-75">
          <div className="p-6 lg:p-8">
            <div className="grid grid-cols-1 items-start gap-6 sm:grid-cols-2 lg:grid-cols-4">
              <PublicationCategories
                categories={publication?.publicationCategories}
              />
              <div className="text-sm text-gray-500  ">
                <ESTTurnaroundTimeLabel
                  turnaroundTime={publication.turnaroundTime}
                />
              </div>

              <DomainAuthorityRankingLabel
                domainAuthority={publication.domainAuthority}
                domainRanking={publication.domainRanking}
              />

              <div className="flex items-center gap-1 flex-wrap">
                {publication?.isDoFollow && (
                  <IsDoFollowLabel
                    isDoFollow={publication?.isDoFollow}
                    doFollowLinksAllowed={publication?.doFollowLinksAllowed}
                  />
                )}
                {publication?.isSponsored && (
                  <SponsorLabel2 isSponsored={publication?.isSponsored} />
                )}
                {publication?.news && <NewsLabel news={publication?.news} />}
              </div>
            </div>

            <div className=" md:hidden pt-10">
              <PublicationCTAButton
                canViewPricing={canViewPricing}
                isWhitelabelOwner={isWhitelabelOwner}
                isHidden={publication?.isHidden}
                isRequireInquiry={publication?.requiresInquiry}
                handlePublicationSelect={() =>
                  handlePublicationSelect(publication)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicationCardHit;
