import { Configure, InstantSearch } from 'react-instantsearch';

import { searchClient } from '@/lib/utils/aloglia';
import { history } from 'instantsearch.js/es/lib/routers';

import { PUBLICATION_INDEX_NAME } from '@/lib/constants/algolia';
import usePublicationHandler from '@/lib/hooks/usePublicationHandler';
import PublicationDetailsModal from './publicationDetailsModal';
import PublicationInquiryModal from './publicationInquiryModal';
import SupportCard from './supportCard';
import DealsPublicationsList from './widgets/dealsPublicationList';
import Filters from './widgets/filters';

import { usePublicationStore } from '@/lib/store/publication-list';
import { cn } from '@/lib/utils/generalUtils';
import { useRouter } from 'next/router';
import PublicationList from './publicationList';

const PressListWithAlgolia = ({
  siteId,
  canViewDoFollowAndSponsored,
  showBasePrices,
  showDeals = true,
  isWhitelabelOwner,
  isShared = false,
}) => {
  const {
    handlePublicationDetailsOpen,
    handlePublicationInquiryOpen,
    isDetailOpen,
    setDetailOpen,
    isInquiryOpen,
    handleCloseInquiryModal,
    highlightedPublication,
  } = usePublicationHandler();

  const router = useRouter();
  const { selectedSavedList } = usePublicationStore((state) => state);
  const listName = router?.query?.list_name;

  return (
    <>
      <div className="container mx-auto px-6 max-w-7xl">
        <InstantSearch
          searchClient={searchClient}
          indexName={PUBLICATION_INDEX_NAME}
          routing={{
            router: history({
              cleanUrlOnDispose: false,
            }),

            stateMapping: {
              stateToRoute: (uiState) => {
                const indexUiState = uiState[PUBLICATION_INDEX_NAME];
                const { configure, geoSearch, ...rest } = indexUiState;

                return {
                  ...rest,
                  list_name: indexUiState?.refinementList?.id
                    ? selectedSavedList?.name || listName
                    : undefined,
                };
              },
              routeToState: (routeState) => {
                return {
                  [PUBLICATION_INDEX_NAME]: {
                    ...routeState,
                  },
                };
              },
            },
          }}
          future={{ preserveSharedStateOnUnmount: true }}
          stalledSearchDelay={700}
        >
          <Configure
            filters="is_hidden:false"
            typoTolerance={true}
            ignorePlurals={true}
            numericRefinements={{
              publishedAt_timestamp: {
                '>': [0],
              },
            }}
            hitsPerPage={25}
          />
          <div className="flex gap-x-6 py-16 relative">
            <div
              className={cn(
                'hidden lg:block lg:sticky lg:top-0',
                isShared && 'hidden lg:hidden'
              )}
            >
              <Filters />
            </div>
            <div className="flex-1 w-full">
              {showDeals && (
                <DealsPublicationsList
                  siteId={siteId}
                  handlePublicationInquiryOpen={handlePublicationInquiryOpen}
                  handleRequirementsOpen={handlePublicationDetailsOpen}
                  showBasePrices={showBasePrices}
                  isWhitelabelOwner={isWhitelabelOwner}
                />
              )}

              <PublicationList
                handlePublicationInquiryOpen={handlePublicationInquiryOpen}
                handleRequirementsOpen={handlePublicationDetailsOpen}
                isWhitelabelOwner={isWhitelabelOwner}
                listName={listName}
              />

              <SupportCard siteId={siteId} />
            </div>
          </div>
        </InstantSearch>
      </div>

      {isDetailOpen && (
        <PublicationDetailsModal
          setIsOpen={setDetailOpen}
          isOpen={isDetailOpen}
          publication={highlightedPublication}
          canViewDoFollowAndSponsored={canViewDoFollowAndSponsored}
          handlePublicationInquiryOpen={handlePublicationInquiryOpen}
        />
      )}

      {isInquiryOpen && (
        <PublicationInquiryModal
          site_id={siteId}
          publication={highlightedPublication}
          isOpen={isInquiryOpen}
          handleClose={handleCloseInquiryModal}
        />
      )}
    </>
  );
};

export default PressListWithAlgolia;
