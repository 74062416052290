import Press from '@/components/icons/press';
import Search from '@/components/icons/search';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';
import { useSavedList } from '@/lib/hooks/useSavedList';
import { usePublicationStore } from '@/lib/store/publication-list';
import { sleep } from '@/lib/utils/generalUtils';

import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useInstantSearch } from 'react-instantsearch';

export const SAVED_LIST_QUERY_KEY = 'saved_list';

const SavedList = () => {
  const [value, setValue] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const router = useRouter();
  const { data: savedLists, isLoading } = useSavedList();
  const { setIndexUiState } = useInstantSearch();
  const handleSelectedList = usePublicationStore(
    (state) => state.handleSelectedSavedList
  );

  if (isLoading || savedLists?.length === 0) return null;

  const filteredSavedLists =
    searchValue === ''
      ? savedLists
      : savedLists?.filter((list) => {
          return list.name?.toLowerCase() === searchValue.toLowerCase();
        });

  return (
    <Accordion type="single" collapsible value={value} onValueChange={setValue}>
      <AccordionItem value="saved_list" className="border-none">
        <AccordionTrigger
          className={classNames(
            'filter-heading font-semibold hover:no-underline !py-0',
            value === 'saved_list' && 'text-primary-900'
          )}
        >
          <div className="flex items-center gap-x-2 filter-heading">
            <Press />
            Saved Lists
          </div>
        </AccordionTrigger>
        <AccordionContent className="border bg-white border-neutral-300 p-2 mt-3 rounded-md">
          <div className="relative w-full mb-6">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 z-10">
              <Search className="icon-xs text-neutral-500" aria-hidden="true" />
            </div>
            <input
              id="search_saved_list"
              placeholder="Search List"
              className="input !pl-8"
              defaultValue={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </div>

          <div className="space-y-6">
            {searchValue && filteredSavedLists?.length === 0 && (
              <p className="text-neutral-600 py-5 text-center text-sm">
                No{' '}
                <span className="font-semibold">&quot;{searchValue}&quot;</span>{' '}
                saved list found
              </p>
            )}

            <div className="space-y-3">
              {filteredSavedLists?.map((list) => {
                const publications = list.publications.map((pub) =>
                  pub.id.toString()
                );

                const isEmptyList = publications?.length === 0;

                return (
                  <button
                    type="button"
                    key={list.id}
                    className="block font-bold text-base disabled:opacity-30"
                    disabled={isEmptyList}
                    onClick={() => {
                      setIndexUiState((prevIndexUiState) => {
                        return {
                          ...prevIndexUiState,
                          refinementList: {
                            ...prevIndexUiState.refinementList,
                            id: publications,
                            location: [],
                          },
                          page: 0,
                          query: '',
                          sortBy: '',
                          range: {
                            price: '0',
                            domain_authority: '0',
                            domain_ranking: '0',
                            min_turnaround_time_in_days: '0',
                            max_turnaround_time_in_days: '0',
                          },
                          menu: {
                            'publication_categories.name': '',
                          },
                          toggle: {
                            is_do_follow: false,
                            is_sponsored: false,
                            news: false,
                          },
                        };
                      });
                      handleSelectedList(list);
                      sleep(500).then(() => {
                        let search = `${window.location.search}`;
                        if (search.includes('list_name')) {
                          search = search.replace('&list_name=', '');
                        }
                        router.push(
                          `${window.location.href}&list_name=${list?.name}`,
                          undefined,
                          { shallow: true }
                        );
                      });
                    }}
                  >
                    {list.name}{' '}
                    {isEmptyList && <i className="text-xs">(empty)</i>}
                  </button>
                );
              })}
            </div>
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default SavedList;
